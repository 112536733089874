.Search {
    width: 250px;
    height: 100%;
}

.Search input {
    box-sizing: border-box;
    width: 80px;
    /* width: 150px; */
    font-size: 1rem;
    padding: 8px;
    border-radius: 5px 0 0 5px;
    border: 1px solid #CCCCCC;
    display: inline-block;
    margin: 4% -1px 5% 6%;
    /* margin: 4% -1px 5% 15%; */
    outline: none;
    color: rgba(0, 0, 0, 0.7);
}

.Search button {
    display: inline-block;
    box-sizing: border-box;
    width: 40px;
    font-size: 1rem;
    padding: 8px;
    /* line-height: 28px; */
    /* top: 1px; */
    background-color: var(--wnw2Primary);
    color: white;
    text-align: center;
    border: 1px solid #CCCCCC;
    border-radius: 0 5px 5px 0;
    cursor: pointer;
    outline: none;
}

@media (min-width: 1000px) {
    .Search {
        width: 300px;
    }
    
    .Search input {
        width: 200px;
        margin: 5% 0 5% 10%;
        padding: 7px;
    }

    .Search button {
        cursor: pointer;
        border: none;
    }
}