#search-form {
    display: none;
    width: 3rem;
    height: 3rem;
    box-sizing: border-box;
    background: white;
    border-radius: 1.5rem;
    cursor: pointer;
}

#search-input {
    display: none;
    position: absolute;
    top: 0.5rem;
    right: 0;
    width: 3rem;
    height: 1rem;
    line-height: 30px;
    outline: 0;
    border: 0;
    border-radius: 20px;
    padding: 1rem 2rem 1rem 1rem;
    cursor: pointer;
    display: block;
}
