.SideDrawer {
    position: fixed;
    width: 280px;
    max-width: 70%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 200;
    background-color: white;
    box-sizing: border-box;
    transition: transform 0.3s ease-out;
}

.MobileOnly {
    display: block;
}

@media (min-width: 1000px) {
    .SideDrawer {
        width: 25%;
    }

    .MobileOnly {
        display: none;
    }
}

.Header {
    height: 56px;
    background-color: var(--wnw2Background);
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
}

.Contents {
    padding: 32px 16px;
}

.Open {
    transform: translateX(0);
}

.Close {
    transform: translateX(-100%);
}

.Logo {
    height: 11%;
    margin-bottom: 32px;
}