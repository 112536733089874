.RightDrawer {
    position: fixed;
    width: 375px;
    max-width: 85%;
    height: 100%;
    right: 0;
    top: 0;
    z-index: 200;
    background-color: white;
    box-sizing: border-box;
    transition: transform 0.3s ease-out;
    overflow-y: auto;
}

@media (min-width: 1000px) {
    .RightDrawer {
        width: 40%;
    }
}

.Open {
    transform: translateX(0);
}

.Close {
    transform: translateX(100%);
}
