.HeaderRight {
    width: 350px;
    margin: 0 5px 0 0;
    display: flex;
    justify-content: flex-start;
    flex-flow: row;
    flex-wrap: wrap;
}

.HeaderRightItem {
    box-sizing: border-box;
    margin: 0;
    padding: 0 10px;
    text-align: right;
}

.HeaderRightItem > div {
    display: inline-block;
    cursor: pointer;
}