.NavItems {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    /* justify-content: flex-start; */
    /* align-items: center; */
    height: 100%;
}

.NavItem {
    margin: 10px 0;
    box-sizing: border-box;
    display: block;
    height: 100%;
}

.NavItem a {
    color: white;
    text-decoration: none;
    width: 100%;
    box-sizing: border-box;
    display: block;
}

.NavItem a:hover,
.NavItem a:active,
.NavItem a.active {
    color: white;
}

@media (min-width: 1000px) {
    .NavItems {
        flex-direction: row;
    }

    .NavItem {
        margin: 0;
        display: flex;
        height: 100%;
        width: auto;
        align-items: center;
        width: 240px !important;
        /* text-align: center; */
    }
    
    .NavItem a {
        color: white;
        height: 100%;
        padding: 10px 15px;
        border-bottom: 4px solid transparent;
    }
    
    /* .NavItem a.active, */
    .NavItem a:hover,
    .NavItem a:active {
        /* border-bottom: 4px solid var(--wnw2Background);
        color: white; */
    }
}