.Text {
    z-index: 101;
    color: white;
    position: fixed;
    top: 52%;
    left: 41%;
    font-size: 1.2rem;
}

.Loader,
.Loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.Loader {
  /* margin: 60px auto; */
  z-index: 101;
  top: 30%;
  left: 35%;
  font-size: 10px;
  /* position: relative; */
  position: fixed;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(0,0,0, 0.2);
  border-right: 1.1em solid rgba(0,0,0, 0.2);
  border-bottom: 1.1em solid rgba(0,0,0, 0.2);
  border-left: 1.1em solid #FFFFFF;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media (min-width: 1000px) {
    .Text {
        top: 48%;
        left: 47%;
    }

    .Loader {
        left: 45%;      
    }
}
