html {
    font-size: 15px;
    color: rgba(0, 0, 0, 0.7);
    scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: 'Noto Sans TC', "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input, select, textarea, button {
    font-family: inherit;
    font-size: inherit;
}

:root {
    --wnw2Primary: #10191E;
    --wnw2PrimaryRgb: 16, 25, 30;
    --wnw2Background: #2DD5C4;
    --wnw2BackgroundRgb: 45, 213, 196;
    --wnwLightBlue: #40A4C8;
    --wnwOrange: #FFCC80;
    --wnwLightOrange: #FFCC80;
    --wnwGreen: #28a745;
    --wnwRed: #944317;
    --wnwLightRed: #FF726F;
}

.floating-label input, .react-date input {
    font-family: 'Noto Sans TC', 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif !important;
}

.floating-label span {
    font-family: 'Noto Sans TC', 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif !important;
}

.floating-label textarea {
    font-family: 'Noto Sans TC', 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif !important;
}

.validation-error {
    color: red;
    margin: 0 0 0 0.5rem;
    font-size: 0.8rem;
}

.notification {
    padding: 15px !important;
}
.notification-container {
    top: 120px !important;
}

.notification-message {
    text-align: center;
}

.notification-success {
    background-color: rgba(0, 0, 0, 0.8) !important;
    /* background-color: var(--wnw2Primary) !important; */
}

.notification-error:before, .notification-success:before, .notification-warning:before, .notification-info:before {
    content: none !important;
}

.notification-info {
    background-color: rgba(0, 0, 0, 0.8) !important;
    /* background-color: var(--wnw2Primary) !important; */
}

/* Chrome, Safari, Edge, Opera */
/* This removes the up-down buttons in input boxes */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}
